<template>
  <router-view
    ref="bookingConfirmation"
    @clear-order-created="$emit('clear-order-created')"
    @validate-booking="$emit('validate-booking', $event)"
    @loading-booking="(status) => $emit('loading-booking', status)"
  />
</template>
<script>
import ProductCheckoutMixins from '@/modules/product/mixins/ProductCheckoutMixins.vue';

export default {
  name: 'PaymentPage',
  mixins: [ProductCheckoutMixins],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.fullPath.includes('redirect_status')) {
        vm.$router.push({ name: `${vm.$route.meta.baseName}-status` });
      } else if (!vm.localOrder) next({ name: to.meta.fallbackPathName });
    });
  },
  props: {
    localOrderId: String,
  },
  methods: {
    onPaymentButtonClick() {
      this.$refs.bookingConfirmation.onPaymentButtonClick();
    },
  },
};
</script>
